import { Grid, Col, Button, ProgressBar, TextInput } from "@tremor/react";
import { ReCaptcha } from "next-recaptcha-v3";
import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useRollbar } from "@rollbar/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

import getLanguage from "@/app/utils/getlanguage";
import cleanUpText from "@/app/utils/cleanup.text";
import translation from "./locale/index.json";
import Typewriter from "@/app/components/Typewriter";
import { IConversationTask } from "@/app/interfaces/IConversationTask";
import convertMarkdownToHTML from "@/app/utils/markdown";

export interface IServiceChat {
  userAgent: string;
  referer: string;
  language: string;
  taskId?: string | null;
  content: { greeting: string };
  minHeight?: string;
  maxHeight?: string;
}

const header = {
  "Content-Type": "application/json",
};

export default function ServiceChat(props?: { userInfo: IServiceChat }) {
  const { locale } = useRouter();
  const logger = useRollbar();
  const content = getLanguage(translation, locale);
  const [progress, setProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(35);
  const [ask, setAsk] = useState("");
  const [typeString, setTypeString] = useState("");
  const [clear] = useState(false);
  const [conversation, setConversation] = useState<IConversationTask[]>([]);
  const [token, setToken] = useState<string | null>(null);
  const [score, setScore] = useState(0);
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    convertMarkdownToHTML(typeString).then(txt => setMarkdown(txt));
  }, [typeString, markdown, setMarkdown, score, locale]);

  useEffect(() => {
    if (token) {
      fetch("/api/auth/recaptcha", {
        headers: header,
        method: "POST",
        body: JSON.stringify({ token }),
      })
        .then(async response => {
          const responseRecaptcha = await response.json();
          if (responseRecaptcha.status === 200) {
            setScore(responseRecaptcha.score);
          }
        })
        .catch(err => {
          logger.warn(`chat-bot recaptcha ${err?.message}`, err);
        });
    }
  }, [token, logger]);

  const start = useCallback(async () => {
    // if (score < 0.5) {
    //   toast.error(`${content.dismiss} ${score}`);
    //   return;
    // }

    try {
      setProgress(true);
      setProgressValue(15);
      setTimeout(() => setProgressValue(55), 1500);
      setTimeout(() => setProgressValue(75), 2200);
      setTimeout(() => setProgressValue(95), 3200);
      const context = {
        taskId: props?.userInfo.taskId || undefined,
        ask: cleanUpText(
          `<user_browser>${props?.userInfo.userAgent}</user_browser>
          <user_referer_site>${props?.userInfo.referer}</user_referer_site>
          <user_language>${locale}</user_language> ${ask ? ask : "Powiedz coś więcej o usłudze"}`,
        ),
        conversation,
      };

      const response = await fetch("/api/services/chat", {
        method: "POST",
        headers: header,
        body: JSON.stringify(context),
      });

      const json = await response.json();
      const { status, data, error } = json;

      if (status >= 200 && status < 202) {
        setAsk("");
        let text = [];

        if (data.match(/FINISH_ACTION/gu)) {
          const _space = `\n ${content.finishText}`;
          text = _space?.replace(/\d+\. /g, "\n").split("\n") || [];
        } else {
          const _space = `\n ${data}`;
          text = _space?.replace(/\d+\. /g, "\n").split("\n") || [];
          const newConversation = [
            ...conversation,
            {
              user: context.ask,
              assistant: data,
            },
          ];
          setConversation(newConversation);
          if (newConversation && window)
            window.localStorage.setItem("conversations", JSON.stringify(newConversation.slice(-19)));
        }

        setTypeString(text?.join("<br>") || " ");
      } else {
        toast.error(error);
      }
    } catch (error) {
      setProgress(false);
      if (error) logger.error("Error saving chat", error);
    } finally {
      setProgressValue(0);
      setProgress(false);
    }
  }, [ask, content.finishText, conversation, locale, logger]);

  useEffect(() => {
    const db = window.localStorage.getItem("conversations") || "[]";
    let dbConvesation = [];
    if (db) {
      dbConvesation = JSON.parse(db);
      setConversation(dbConvesation);
    }
    setTimeout(() => setTypeString(props?.userInfo.content.greeting || ""), 700);
  }, [props]);

  return (
    <>
      <ReCaptcha onValidate={setToken} action="submit" />
      <div className="h-full w-full">
        <Grid numItems={1} className="w-full gap-2 px-auto pr-4 pl-4">
          <Col
            numColSpan={1}
            className={`w-full min-h-[${props?.userInfo.minHeight || "30vh"}] max-h-[${
              props?.userInfo.maxHeight || "50vh"
            }] text-xl sm:text-2xl text-wrap`}
          >
            <Typewriter text={markdown} clear={clear} maxHeight={props?.userInfo.maxHeight || "50vh"} />
          </Col>
        </Grid>

        <Grid numItems={1} numItemsSm={12} className="w-full pt-10 pb-10 gap-2 px-auto pr-4 pl-4">
          {progress ? (
            <Col numColSpan={1} numColSpanSm={12}>
              <ProgressBar value={progressValue} />
            </Col>
          ) : (
            ""
          )}

          <Col numColSpanSm={10} numColSpan={1} className="w-full">
            <TextInput
              onKeyDown={e => (e.keyCode === 13 ? start() : null)}
              autoFocus={true}
              value={ask}
              onChange={e => setAsk(e.target.value)}
            />
          </Col>

          <Col numColSpan={1} numColSpanSm={2} className="w-full my-auto mx-auto h-full">
            <Button icon={PaperAirplaneIcon} className="w-full bg-[#273c49] border-[#273c49]" onClick={start}>
              {/* {content.btn} */}
            </Button>
          </Col>
        </Grid>
      </div>
    </>
  );
}
