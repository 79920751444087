import { useState, useEffect, useCallback } from "react";

export const useTypewriter = (
  text: string,
  speed = 25,
  clear = false
): string | null => {
  const [displayText, setDisplayText] = useState("");

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const type = useCallback(
    async (text: string) => {
      for (let i = 0; i < text.length; i++) {
        setDisplayText((prevText) => `${prevText || ""}${text[i]}`);
        await sleep(speed);
      }
    },
    [speed]
  );

  useEffect(() => {
    if (clear) {
      setDisplayText("");
      return;
    }
    type(text);
  }, [text, clear, type]);

  return clear ? null : displayText;
};
