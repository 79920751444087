import { useTypewriter } from "@/hooks/useTypewriter";
import { useEffect, useRef } from "react";

const Typewriter = (props: {
  text: string;
  clear?: boolean;
  speed?: number;
  maxHeight?: string;
  className?: string;
}) => {
  const { text, speed, clear, maxHeight, className } = props;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [divRef.current?.scrollHeight]);

  const displayText = useTypewriter(text, speed, clear) || " ";
  const contentWithCursor = `${displayText}<span class="animate-[ping_0.8s_ease-in-out_infinite] font-extrabold">|</span>`;

  return (
    <div
      ref={divRef}
      className={`${className} w-full p-2 max-h-[${maxHeight || "50vh"}] overflow-y-auto bg-transparent`}
      dangerouslySetInnerHTML={{ __html: contentWithCursor }}
    />
  );
};

export default Typewriter;
